<template>
    <div>
        <vue-header title="添加用户" isReturn="true" />
        <div class="user-code">
            <div class="code-wx">请使用<span>微信</span>扫一扫</div>
            <div class="code-wx">扫描以下二维码</div>
            <div class="code-img">
                <img :src="code"/>
            </div>
        </div>
    </div>
</template>

<script>
import vueHeader from '@/components/header';
import { makecode , getDomain } from "@/utils";
import domain from "@/utils/config";
export default {
  name: 'addUserCode',
  components:{
    vueHeader
  },
  data() {
    return {
        code:makecode(`http://${getDomain()}${domain}/?Join=1&source=1`)
    };
  },
  methods:{
      back(){
          window.history.back(-1);
      }
  }
}
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import 'addUserCode';
</style>